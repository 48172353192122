import { JUSTIFY_CONTENT_OPTIONS, TEXT_ALIGNMENT } from '~~/constants/style'
import { formatMargin, formatPadding } from '../../style'
import { formatWidth, formatHeight } from '~/models/layout/style';
import { getBorder } from '@/models/general/BorderModel'

import Palette from "~/models/palette/Palette";

import { CARD_ORIENTATION } from '~/constants/card'
import { formatFontStyle } from '../../style/font'
import { formatComponentStyle } from '../../style/component'

export class CardModel {
  constructor() {
    this.titleStyle = {}
    this.infoboxFontStyle = {}
    this.infoboxStyle = {}
    this.infoboxWrapperStyle = {}
    this.style = {}
    this.thumbnailStyle = {}
    this.imageStyle = {}
    this.parentGroupStyle = {}
    this.progressBarStyle = null

    this.infoboxClasses = ''

    this.order = 'col'

    this.showTitle = false
    this.showAuthor = false
    this.showViews = false
    this.showDate = false
    this.showThumbnail = false
    this.showTimeDuration = false
    this.showParentGroup = false
    this.showProgressBar = false

    this.isStatic = true
    this.isGif = false
    this.isFilmstrip = true
    this.parentGroupId = null

  }

  fromQuery(data) {
    const {
      titleStyle = {},
      infoboxStyle = {},
      thumbnailStyle = {},
      padding = null,
      aspectRatio = null,
      backgroundColor = null,
      spacing = null,
      skeletonStyle = {},
      groupStyle = {},
      progressBarStyle = null,
    } = data.style

    const {
      title = false,
      author = false,
      views = false,
      date = false,
      thumbnail = false,
      timeDuration = false,
      parentGroup = false,
      progressBar = false,
    } = data.props.components

    const { layout, thumbnailOptions, parentGroupId } = data.props

    this.isStatic = thumbnailOptions?.isStatic
    this.isGif = thumbnailOptions?.isGif
    this.isFilmstrip = thumbnailOptions?.allowFilmstrip

    const { objectFit, xPos, yPos } = thumbnailStyle
    const { radius, show } = thumbnailStyle.borderProperties
    const sceletonColor = skeletonStyle?.backgroundColor


    this.titleStyle = formatTitleStyle(titleStyle)
    this.infoboxFontStyle = formatInfoboxFontStyle(infoboxStyle)
    this.infoboxStyle = formatInfoboxStyle(infoboxStyle)
    this.thumbnailStyle = formatComponentStyle({
      borderProperties: thumbnailStyle.borderProperties,
    })
    if (layout === 'bottom_thumbnail' || layout === 'top_thumbnail') {
      this.style = formatCardStyle({ backgroundColor, spacing, padding, thumbnailStyle })
    }
    else {
      this.style = formatCardStyle({ backgroundColor, spacing, padding })
    }

    this.infoboxWrapperStyle = formatInfoboxWrapperStyle(infoboxStyle)
    this.imageStyle = formatImageStyle({ aspectRatio, objectFit, xPos, yPos, radius, show, sceletonColor })
    this.parentGroupStyle = formatParentGroupStyle(groupStyle)

    if (progressBarStyle) {
      this.progressBarStyle = {
        position: progressBarStyle.position,
        frontBarStyle: formatFrontBarStyle(progressBarStyle),
        backBarStyle: formatBackBarStyle(progressBarStyle),
        isVertical: progressBarStyle.position === 'left' || progressBarStyle.position === 'right',
      }
    }

    this.order = CARD_ORIENTATION[layout]
    this.style.layoutType = layout

    this.showTitle = title
    this.showAuthor = author
    this.showViews = views
    this.showDate = date
    this.showThumbnail = thumbnail
    this.showTimeDuration = timeDuration
    this.showParentGroup = parentGroup
    this.showProgressBar = progressBar

    this.parentGroupId = parentGroupId

    let classes = infoboxStyle.orientation === 'vertical' ? 'flex-col' : 'flex-row'
    classes += ` ${JUSTIFY_CONTENT_OPTIONS[infoboxStyle.alignment]} ${TEXT_ALIGNMENT[infoboxStyle.alignment]} `
    classes += infoboxStyle.showIndicators ? 'infobox' : ''

    this.infoboxClasses = classes
  }
}

class InfoboxWrapperStyleModel {
  constructor() {
    this.backgroundColor = null

    this.borderTopLeftRadius = null
    this.borderTopRightRadius = null
    this.borderBottomLeftRadius = null
    this.borderBottomRightRadius = null
  }

  fromData({ backgroundColor, borderProperties }) {
    this.backgroundColor = backgroundColor

    if (borderProperties.show) {
      this.borderTopLeftRadius = `${borderProperties.radius.top}px`
      this.borderTopRightRadius = `${borderProperties.radius.right}px`
      this.borderBottomLeftRadius = `${borderProperties.radius.bottom}px`
      this.borderBottomRightRadius = `${borderProperties.radius.left}px`
    } else {
      delete this.borderTopLeftRadius
      delete this.borderTopRightRadius
      delete this.borderBottomLeftRadius
      delete this.borderBottomRightRadius
    }
  }
}

class InfoboxStyleModel {
  constructor() {
    this.gap = null
  }

  fromData({ spacing }) {
    if (spacing) this.gap = `${spacing}px`
  }
}

class CardStyleModel {
  constructor() {
    this.backgroundColor = null
    this.gap = null
    this.borderTopLeftRadius = null
    this.borderTopRightRadius = null
    this.borderBottomLeftRadius = null
    this.borderBottomRightRadius = null
  }

  fromData({ backgroundColor, spacing, thumbnailStyle }) {
    this.backgroundColor = backgroundColor
    this.gap = `${spacing}px`

    if (!thumbnailStyle) return
    this.borderTopLeftRadius = `${thumbnailStyle?.borderProperties?.radius?.top}px`
    this.borderTopRightRadius = `${thumbnailStyle?.borderProperties?.radius?.right}px`
    this.borderBottomLeftRadius = `${thumbnailStyle?.borderProperties?.radius?.bottom}px`
    this.borderBottomRightRadius = `${thumbnailStyle?.borderProperties?.radius?.left}px`

  }
}

export class ImageStyleModel {
  constructor() {
    this.aspectRatio = null
    this.aspectRatioProperty = null
    this.objectFit = null
    this.objectPosition = null
    this.width = null
    this.height = null

    this.backgroundColor = '#f5f5f5'

    this.borderTopLeftRadius = null
    this.borderTopRightRadius = null
    this.borderBottomLeftRadius = null
    this.borderBottomRightRadius = null
  }

  fromData({
    aspectRatio,
    objectFit,
    xPos,
    yPos,
    radius,
    show,
    width,
    height,
    sceletonColor
  }) {
    this.aspectRatio = aspectRatio?.value
    this.aspectRatioProperty = aspectRatio?.eventValue

    this.objectFit = objectFit
    if (yPos || xPos) this.objectPosition = `${xPos}px ${yPos}px`
    if (width) this.width = `${width}%`

    this.backgroundColor = sceletonColor || '#f5f5f5'

    if (height)
      this.height =
        height.type === 'px' || height.type === '%'
          ? `${height.value}${height.type}`
          : `${height.type}`

    if (show) {
      this.borderTopLeftRadius = `${radius.top}px`
      this.borderTopRightRadius = `${radius.right}px`
      this.borderBottomLeftRadius = `${radius.bottom}px`
      this.borderBottomRightRadius = `${radius.left}px`
    } else {
      delete this.borderTopLeftRadius
      delete this.borderTopRightRadius
      delete this.borderBottomLeftRadius
      delete this.borderBottomRightRadius
    }
  }
}


class FrontBarStyleModel {
  constructor() {}
  fromData({ width = null, height = null, backgroundColorFront = null }) {
    if (height) {
      this.height = formatHeight(height)
    }

    if (width) {
      this.width = formatWidth(width)
    }

    if (backgroundColorFront) {
      const palette = new Palette()
      palette.checkColor(backgroundColorFront)
      this.backgroundColor = palette.color
    }
  }
}

class BackBarStyleModel {
  constructor() {}
  fromData({
    padding = null,
    margin = null,
    borderProperties = null,
    backgroundColorBack = null,
  }) {
    if (padding) {
      const s = formatPadding(padding)
      this.padding = s.padding
    }
    if (margin) {
      const s = formatMargin(margin)
      this.margin = s.margin
    }

    if (borderProperties?.show) {
      const { borderRadius } = getBorder(borderProperties)
      this.borderRadius = borderRadius
    }

    if (backgroundColorBack) {
      const palette = new Palette()
      palette.checkColor(backgroundColorBack)
      this.backgroundColor = palette.color
    }
  }
}

function formatInfoboxWrapperStyle(data) {
  const infobox = new InfoboxWrapperStyleModel()
  infobox.fromData(data)

  const padding = formatPadding(data.padding)
  const margin = formatMargin(data.margin)

  return { ...infobox, ...padding, ...margin }
}

function formatTitleStyle(data) {
  const { fontProperties, alignment, margin, padding } = data
  const font = formatFontStyle({ ...fontProperties, textAlignment: alignment })

  const p = formatPadding(padding)
  const m = formatMargin(margin)

  return { ...font, ...p, ...m }
}

function formatInfoboxFontStyle(data) {
  return formatFontStyle(data.fontProperties)
}

function formatInfoboxStyle(data) {
  const infobox = new InfoboxStyleModel()
  infobox.fromData(data)

  return infobox
}

function formatParentGroupStyle(data) {
  return { ...formatFontStyle(data.fontProperties), textAlign: data.alignment }
}

function formatCardStyle(data) {
  const card = new CardStyleModel()
  card.fromData(data)

  const padding = formatPadding(data.padding)

  return { ...card, ...padding }
}

function formatImageStyle(data) {
  const imageStyle = new ImageStyleModel()
  imageStyle.fromData(data)

  return imageStyle
}

function formatFrontBarStyle(data) {
  if (!data) return {}

  const { position, width, height, backgroundColorFront } = data
  const c = new FrontBarStyleModel()

  if (position === 'left' || position === 'right')
    c.fromData({ width, backgroundColorFront })
  else c.fromData({ height, backgroundColorFront })

  return c
}

function formatBackBarStyle(data) {
  if (!data) return

  const c = new BackBarStyleModel()
  c.fromData(data)

  return c
}